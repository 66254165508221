import { createContext } from 'react'
import { Item } from 'common/models/Item'
import {PriceAdjustmentDelta} from "../../common/models/PriceAdjustment";
import {BarcodeSelection} from "../../common/models/BarcodeSelection";

interface IBarcodesContext {
  isProductSelectorOpen: boolean
  isBarcodeSelectionOpen: boolean
  isPriceAdjustmentOpen: boolean
  allItems: Item[]
  selectedItems: Item[]
  stickerQuantities: any
  prices: any
  priceAdjustment: PriceAdjustmentDelta

  closeProductSelector()
  closeBarcodeSelection()
  closePriceAdjustment()
  openProductSelector()
  openBarcodeSelection()
  openPriceAdjustment()
  setSelectedItems(items: Item[])
  setStickerQuantities(quantities: any)
  setPrices(prices: any)
  setPriceAdjustment(adjustment: PriceAdjustmentDelta)
  saveBarcodesSelection(selectionName: string): Promise<void>
  deleteBarcodesSelection(barcodeSelection: BarcodeSelection)
  loadBarcodesSelection(barcodeSelection: BarcodeSelection)
  generatePdf()

  isPriceAdjusted(): boolean
  calculatePrice(originalPrice: number): number
  priceAdjustmentTitle(): string
}

export const BarcodeContext = createContext<IBarcodesContext>(
  {} as any,
)
