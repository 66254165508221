import { createContext } from 'react'
import {Customer} from "../../common/models/Customer";
import {Invoice} from "../../common/models/Invoice";
import {Item} from "../../common/models/Item";
import {TaxCode} from "../../common/models/TaxCode";

interface IInvoicesContext {
  invoiceSummaries: Invoice[]
  isLoading: boolean
  invoice?: Invoice
  customers: Customer[]
  items: Item[]
  taxCodes: TaxCode[]
  loadInvoice(invoice: Invoice)
}

export const InvoicesContext = createContext<IInvoicesContext>(
  {} as any,
)
